import React from "react";
import theme from "theme";
import { Theme, Image, Box, Link, Section, Text, Strong, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Help for you — Healthcare without the system
			</title>
			<meta name={"description"} content={"Chat with a doctor right from your phone, all day and night. No waiting room or appointment needed."} />
			<meta property={"og:title"} content={"Help for you — Healthcare without the system"} />
			<meta property={"og:description"} content={"Chat with a doctor right from your phone, all day and night. No waiting room or appointment needed."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Image
			md-top="300px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			position="absolute"
			right="0px"
			bottom="auto"
			z-index="1"
			lg-right="-200px"
			md-width="600px"
			left="auto"
			width="873.4387948069854px"
			height="567.5384131433823px"
			top="123px"
		/>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="10px 0 0px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="30%"
				sm-width="50%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Image height="auto" src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/Accounting%201%20Logo%20ISL.png?v=2023-03-05T00:45:17.094Z" width="100%" max-width="171px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="70%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="0px 0px 0px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/#about"
							font="--base"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							About
						</Link>
						<Link
							color="--grey"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="#services"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							Services
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#testimonials"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							Testimonials
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#team"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							Team
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#contact"
							text-decoration-line="initial"
							margin="0px 20px 0px 20px"
						>
							Contact
						</Link>
						<Link
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="white"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							text-decoration-line="initial"
							margin="0px 20px 0px 20px"
							display="flex"
							background-color="--color-primary"
							padding="10px 15px"
							href="/client-portal"
						>
							Client Portal
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="60%"
				padding="40px 100px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--grey"
					lg-text-align="left"
					font="--base"
					text-transform="uppercase"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					CPA, CMA, CFA{" "}
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Secure Your Financial Future
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					Over 20 years of experience providing financial services to businesses of all sizes in the New York Tri-State area.
				</Text>
				<Box
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="36px 0px 0px 0px"
					display="flex"
					margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/login"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
					>
						Contact Us
					</Link>
					<Link
						padding="15px 30px 15px 30px"
						font="--lead"
						border-radius="10px"
						border-width="2px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						border-style="solid"
						md-width="50%"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-text-align="center"
						sm-padding="15px 13px 15px 13px"
						text-decoration-line="initial"
						color="--white"
						background="rgba(255, 5, 5, 0)"
						margin="0px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0.3)"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						hover-background="--color-white"
						hover-color="--indigo"
						href="/login"
						lg-padding="15px 23px 15px 23px"
						md-padding="15px 16px 15px 16px"
					>
						Learn more
					</Link>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				width="70%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="600px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/Account_1_hero%20-%20Isolated.png?v=2023-03-05T00:29:13.312Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="80px 0px 60px 0" sm-padding="40px 0 0px 0" id="about">
			<Override slot="SectionContent" align-items="center" color="#5D6D7E" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--indigo"
			>
				About Us
			</Text>
			<Text
				as="p"
				font="normal 400 18px/1.5 --fontFamily-googleInter"
				margin="40px 0 20px 0"
				text-align="center"
				color="#566573"
				width="80%"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Financial Solutions{" "}
				</Strong>
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					is
				</Span>
				{" "}a full-service accounting firm located in the heart of New York City. Our team of experienced professionals has been providing quality services to clients for over 20 years. We offer a wide range of services, from basic bookkeeping to complex tax planning and preparation. We also specialize in financial consulting, business valuations, and estate planning.{" "}
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Take control of your financial future today!
					<br />
				</Span>
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0" id="services">
			<Override slot="SectionContent" align-items="center" color="#5D6D7E" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 60px 0"
				color="--indigo"
			>
				Our Services
			</Text>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Image src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/accounting.png?v=2023-03-05T02:13:44.454Z" display="block" width="52px" height="52px" />
					<Text margin="20px 0px 18px 0px" color="#B03A2E" font="normal 600 24px/1.3 --fontFamily-googleInter" lg-text-align="left">
						Accounting
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="normal 400 16px/1.5 --fontFamily-googleInter"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our experienced professionals will take care of every aspect of accounting for you, so you can focus on what matters most - growing your business.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Image src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/011-invoice.png?v=2023-03-05T02:19:48.031Z" display="block" width="52px" height="52px" />
					<Text margin="20px 0px 18px 0px" color="#B03A2E" font="normal 600 24px/1.3 --fontFamily-googleInter" lg-text-align="left">
						Bookkeeping
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="normal 400 16px/1.5 --fontFamily-googleInter"
						lg-text-align="left"
						flex="1 0 auto"
					>
						We record and maintain your entire business record and books, paying attention to every small transaction so  nothing goes under the radar.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Image src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/022-business%20analyst.png?v=2023-03-05T02:23:18.443Z" display="block" width="52px" height="52px" />
					<Text margin="20px 0px 18px 0px" color="#B03A2E" font="normal 600 24px/1.3 --fontFamily-googleInter" lg-text-align="left">
						Tax Planning
						<br />
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="normal 400 16px/1.5 --fontFamily-googleInter"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our team will constantly monitor your earnings to give you the most accurate tax estimates, as well as determine your early returns.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Image src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/021-protect.png?v=2023-03-05T02:24:27.575Z" display="block" width="52px" height="52px" />
					<Text margin="20px 0px 18px 0px" color="#B03A2E" font="normal 600 24px/1.3 --fontFamily-googleInter" lg-text-align="left">
						Tax Resolution
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="normal 400 16px/1.5 --fontFamily-googleInter"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our skilled accountants know how to deal with all sorts of tax issues. Whenever you come across one, we help you out of it.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Image src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/006-newspaper.png?v=2023-03-05T02:25:30.821Z" display="block" width="52px" height="52px" />
					<Text margin="20px 0px 18px 0px" color="#B03A2E" font="normal 600 24px/1.3 --fontFamily-googleInter" lg-text-align="left">
						Payroll Handling
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="normal 400 16px/1.5 --fontFamily-googleInter"
						lg-text-align="left"
						flex="1 0 auto"
					>
						We handle all payroll within your organization, so you can focus on more important stuff to grow your business.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Image src="https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/016-save%20money.png?v=2023-03-05T02:26:53.967Z" display="block" width="52px" height="52px" />
					<Text margin="20px 0px 18px 0px" color="#B03A2E" font="normal 600 24px/1.3 --fontFamily-googleInter" lg-text-align="left">
						Consulting
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="normal 400 16px/1.5 --fontFamily-googleInter"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Last but not least, we learn about your business and give you the best advice you need to make your business a massive success.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" id="testimonials">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--indigo"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Client Testimonials
				</Text>
				<Text margin="0px 0px 0px 0px" color="#566573" text-align="center" font="--lead">
					Our clients' success is a testament to the high-quality services we provide and our commitment to delivering excellence.
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" />
					<Text
						margin="0px 0px 35px 0px"
						color="--darkL2"
						font="normal 500 16px/1.6 --fontFamily-googleInter"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
					>
						"I highly recommend Financial Solutions to anyone looking for reliable and professional service. The team is extremely knowledgeable and helpful. They took the time to explain all the details and answer any questions I had. I am very satisfied with the outcome and will definitely use them again!"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19:47:23.498Z"
							border-radius="50зч"
							margin="0px 15px 12px 0px"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Stephen Blake
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								CEO, Digital Sphere LLC
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" />
					<Text
						margin="0px 0px 35px 0px"
						color="--darkL2"
						font="normal 500 16px/1.6 --fontFamily-googleInter"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
					>
						"I recently worked with them and was impressed with the level of service I received. The team was knowledgeable, professional and friendly. They provided me with sound advice and helped me navigate through the complexities of filing my business taxes. Highly recommend!"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19:47:08.343Z"
							border-radius="50зч"
							margin="0px 15px 12px 0px"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Adriana Williams
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Owner, A&W Events Inc.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" />
					<Text
						margin="0px 0px 35px 0px"
						color="--darkL2"
						font="normal 500 16px/1.6 --fontFamily-googleInter"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
					>
						“My company has been working with this firm for the past five years and they have been nothing short of amazing. Extremely knowledgeable and always provide us with the best advice and guidance. They take the time to explain everything in a way that is easy to understand.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19:46:11.754Z"
							border-radius="50зч"
							margin="0px 15px 12px 0px"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Ethan Hamilton
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								HR Manager, Ric and Bells, Inc.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			font="--base"
			color="--dark"
			background="#EDF2F6"
			id="team"
		>
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 16px 0px"
				color="--indigo"
				font="--headline2"
				text-align="center"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
			>
				Meet Our Team
			</Text>
			<Text
				as="p"
				color="--darkL2"
				margin="0 auto 20px 0"
				text-align="center"
				font="--lead"
			>
				Our experienced professionals are equipped to handle your financial needs with the utmost precision and efficiency.
			</Text>
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGNlb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text color="--darkL2" margin="0" font="italic 16px/27px Inter, sans-serif">
						Chief Executive Officer
					</Text>
					<Text as="h3" font="600 22px/1.3 &quot;Inter&quot;, sans-serif" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Nathan Spencer
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="normal 400 16px/1.5 --fontFamily-googleInter" color="rgba(77, 77, 85, 0.92)">
						35+ years of experience in the accounting industry. Helping businesses and entrepreneurs reach their financial goals with confidence.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://images.unsplash.com/photo-1546672741-d327539d5f13?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text color="--darkL2" margin="0" font="italic 16px/27px Inter, sans-serif">
						Chief Financial Officer
					</Text>
					<Text as="h3" font="600 22px/1.3 &quot;Inter&quot;, sans-serif" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						David Mord
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif" color="rgba(77, 77, 85, 0.92)">
						Financial clarity and security for businesses. Serving as a trusted partner to help organizations maximize their financial potential.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGNlb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text color="--darkL2" margin="0" font="italic 16px/27px Inter, sans-serif">
						Certified Public Accountant
					</Text>
					<Text as="h3" font="600 22px/1.3 &quot;Inter&quot;, sans-serif" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Janet Kalinsky
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif" color="rgba(77, 77, 85, 0.92)">
						Helping businesses reach their financial goals with tailored accounting solutions. Let's get your finances on track!
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YXNpYW4lMjBidXNpbmVzc3dvbWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text color="--darkL2" margin="0" font="italic 16px/27px Inter, sans-serif">
						Senior Accountant
					</Text>
					<Text as="h3" font="600 22px/1.3 &quot;Inter&quot;, sans-serif" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Victoria Kim
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif" color="#191E22">
						I'm here to help you make sense of your finances and reach your financial goals. Let's get started on the path to success!
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6123949e957019001e284458/images/Group%20177.png?v=2021-08-24T00:44:18.633Z) 0% 30% /cover no-repeat scroll padding-box" sm-padding="0px 0 0px 0" md-padding="50px 0 50px 0">
			<Override
				slot="SectionContent"
				display="grid"
				grid-gap="16px"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-gap="0px"
				md-grid-template-columns="repeat(2, 1fr)"
			/>
			<Box
				justify-content="center"
				flex-direction="column"
				background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				align-items="center"
				display="flex"
				min-height="320px"
				empty-min-height="64px"
				empty-border-style="solid"
				padding="0px 0px 60px 0px"
			>
				<Text
					lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
					lg-margin="0px 0px 8px 0px"
					position="relative"
					z-index="1"
					color="--indigo"
					font="normal 700 49px/1.2 --fontFamily-googleInter"
					margin="0px 0px 20px 0px"
				>
					20K+
				</Text>
				<Text
					text-align="center"
					opacity="0.7"
					lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					position="relative"
					z-index="1"
					color="--dark"
					font="--lead"
					margin="0px 0px 0px 0px"
				>
					Tax Returns Filed
				</Text>
			</Box>
			<Box
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
				min-height="320px"
				padding="0px 0px 60px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-items="center"
				justify-content="center"
			>
				<Text
					color="--indigo"
					font="normal 700 49px/1.2 --fontFamily-googleInter"
					margin="0px 0px 20px 0px"
					lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
					lg-margin="0px 0px 8px 0px"
					position="relative"
					z-index="1"
				>
					1K+
				</Text>
				<Text
					md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					z-index="1"
					font="--lead"
					margin="0px 0px 0px 0px"
					text-align="center"
					opacity="0.7"
					position="relative"
					color="--dark"
					lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					Happy Clients
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				flex-direction="column"
				background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				sm-margin="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-border-width="1px"
				justify-content="center"
				min-height="320px"
				md-margin="30px 0px 0px 0px"
				align-items="center"
				padding="0px 0px 60px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
					lg-margin="0px 0px 8px 0px"
					position="relative"
					z-index="1"
					color="--indigo"
					font="normal 700 49px/1.2 --fontFamily-googleInter"
				>
					20+
				</Text>
				<Text
					opacity="0.7"
					md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					z-index="1"
					margin="0px 0px 0px 0px"
					text-align="center"
					lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					position="relative"
					color="--dark"
					font="--lead"
				>
					Years of Experience
				</Text>
			</Box>
			<Box
				flex-direction="column"
				md-margin="30px 0px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				empty-min-width="64px"
				display="flex"
				background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
				padding="0px 0px 60px 0px"
				sm-margin="0px 0px 0px 0px"
				min-height="320px"
				empty-border-width="1px"
				align-items="center"
				justify-content="center"
			>
				<Text
					position="relative"
					z-index="1"
					color="--indigo"
					font="normal 700 49px/1.2 --fontFamily-googleInter"
					margin="0px 0px 20px 0px"
					lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
					lg-margin="0px 0px 8px 0px"
				>
					$50M+
				</Text>
				<Text
					lg-width="70%"
					width="70%"
					position="relative"
					z-index="1"
					color="--dark"
					text-align="center"
					opacity="0.7"
					md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					font="--lead"
					margin="0px 0px 0px 0px"
					lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
				>
					Saved For Our Clients
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});