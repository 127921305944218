export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6403d58aa4bc0400209cd138",
				"6403d58aa4bc0400209cd13c",
				"64043149aed6310020bccd66"
			]
		},
		"6403d58aa4bc0400209cd138": {
			"id": "6403d58aa4bc0400209cd138",
			"name": "404",
			"pageUrl": "404"
		},
		"6403d58aa4bc0400209cd13c": {
			"id": "6403d58aa4bc0400209cd13c",
			"name": "index",
			"pageUrl": "index"
		},
		"64043149aed6310020bccd66": {
			"pageUrl": "client-portal",
			"id": "64043149aed6310020bccd66",
			"name": "Client Portal",
			"seo": {
				"favicon_32px": "https://uploads.quarkly.io/6403d58aa4bc0400209cd124/images/Accounting%201%20Logo%20ISL.png?v=2023-03-05T00:45:17.094Z"
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z",
			"favicon_32px": "https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z",
			"background_win10": "#000848",
			"og:title": "Help for you — Healthcare without the system",
			"og:description": "Chat with a doctor right from your phone, all day and night. No waiting room or appointment needed.",
			"og:image": "https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z",
			"title": "Help for you — Healthcare without the system",
			"description": "Chat with a doctor right from your phone, all day and night. No waiting room or appointment needed."
		}
	}
}