import React from "react";
import { useOverrides, Override, Formspree } from "@quarkly/components";
import { Icon, Text, Strong, Box, Link, Input, Button, Section } from "@quarkly/widgets";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
const defaultProps = {
	"color": "--light",
	"padding": "100px 0",
	"sm-padding": "40px 0",
	"position": "relative",
	"background": "linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1547619292-240402b5ae5d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80)",
	"id": "contact"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap",
			"width": "100%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "50%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "64px 0 0 0",
			"margin": "32px 0 0 0",
			"position": "relative",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-max-width": "100%",
			"font": "--lead"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdLocationOn
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--lead",
			"sm-width": "100%",
			"children": <Strong>
				Visit us
			</Strong>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--lead",
			"sm-width": "100%",
			"children": <>
				4998 Hanover St,
				<br />
				{" "}Suite # 1
				<br />
				New York, NY 10011
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "64px 0 0 0",
			"margin": "64px 0 0 0",
			"position": "relative",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-max-width": "100%"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--lead",
			"sm-width": "100%",
			"children": <Strong>
				Email
			</Strong>
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "mailto:hello@company.com",
			"text-decoration": "none",
			"hover-text-decoration": "underline",
			"color": "--light",
			"sm-width": "100%",
			"font": "--lead",
			"children": "info@financialsolutions.com"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-max-width": "100%",
			"sm-padding": "64px 0 0 0"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdPhone,
			"sm-display": "block"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--lead",
			"sm-width": "100%",
			"children": <Strong>
				Call us
			</Strong>
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--lead",
			"sm-width": "100%",
			"children": <>
				+1 (212) 467-45-32
				<br />
				+1 (212) 567-57-24
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "0",
			"margin": "48px 0",
			"max-width": "360px",
			"position": "relative",
			"display": "flex"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaFacebookF,
			"width": "48px",
			"height": "48px",
			"size": "24px",
			"margin-right": "16px",
			"background": "--color-primary",
			"border-radius": "50%"
		}
	},
	"icon4": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTwitter,
			"width": "48px",
			"height": "48px",
			"size": "24px",
			"margin-right": "16px",
			"background": "--color-primary",
			"border-radius": "50%"
		}
	},
	"icon5": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaLinkedinIn,
			"width": "48px",
			"height": "48px",
			"size": "24px",
			"margin-right": "16px",
			"background": "--color-primary",
			"border-radius": "50%"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"padding": "8px 8px 8px 8px",
			"md-width": "100%",
			"sm-padding": "0px 0px 0px 0px",
			"background": "linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"padding": "56px 48px",
			"margin": "0 0 0 auto",
			"md-max-width": "100%",
			"sm-padding": "24px 12px 36px 12px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "0 0 20px 0",
			"sm-padding": "0px 8px 0px 8px",
			"sm-margin": "0 0 8px 0",
			"children": "Leave us a message"
		}
	},
	"formspree": {
		"kind": "Formspree",
		"props": {
			"endpoint": "xeqpgrlv"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"gap": "16px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "Name"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"name": "name",
			"type": "text"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "Email"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"type": "email",
			"name": "email"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "Message"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"as": "textarea",
			"rows": "4",
			"width": "100%",
			"name": "message"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-end"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-primary"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" md-align-items="center" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Icon {...override("icon")} />
						<Text {...override("text")} />
						<Text {...override("text1")} />
					</Box>
					<Box {...override("box4")}>
						<Icon {...override("icon1")} />
						<Text {...override("text2")} />
						<Link {...override("link")} />
					</Box>
					<Box {...override("box5")}>
						<Icon {...override("icon2")} />
						<Text {...override("text3")} />
						<Text {...override("text4")} />
					</Box>
					<Box {...override("box6")}>
						<Icon {...override("icon3")} />
						<Icon {...override("icon4")} />
						<Icon {...override("icon5")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Box {...override("box8")}>
					<Box {...override("box9")}>
						<Text {...override("text5")} />
						<Formspree {...override("formspree")}>
							<Box {...override("box10")}>
								<Box {...override("box11")}>
									<Text {...override("text6")} />
									<Input {...override("input")} />
								</Box>
								<Box {...override("box12")}>
									<Text {...override("text7")} />
									<Input {...override("input1")} />
								</Box>
								<Box {...override("box13")}>
									<Text {...override("text8")} />
									<Input {...override("input2")} />
								</Box>
								<Box {...override("box14")}>
									<Button {...override("button")}>
										Send
									</Button>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;